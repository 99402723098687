<template>
  <div>
    <div v-if="isLoading" class="loading-bar">
      <div class="loading-bar__progress" :style="{ width: loadingPercentage + '%' }"></div>
    </div>
    <div v-if="!isLoading" class="home-page">
    <section class="home-page__head">
      <video class="home-page__video" autoplay loop muted playsinline>
        <source src="../assets/abstract-video.mp4" type="video/mp4">
        Tu navegador no soporta el video.
      </video>
      <h1 class="home-page__head__title">milo</h1>
      <div class="home-page__head__left">
        <p class="home-page__head__text">Product Designer</p>
      </div>
      <div class="home-page__head__right">
        <p>Llevo +10 años diseñando experiencias digitales.</p>
        <span>Medellín, Colombia</span>
        <br>
        <a href="https://www.linkedin.com/in/milometaute/" target="_blank" class="home-page__head__right__button">Contactar en Linkedin</a>
      </div>
    </section>

    <section class="home-page__body">
      <div class="home-page__body__columns" v-for="(item, index) in items" :key="index">
        <div class="home-page__body__columns__content">
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
        <img :src="item.image" alt="" class="home-page__body__columns__image">
      </div>
    </section>

    <section class="home-page__footer">
      <h5>Camilo Metaute</h5>
    </section>
  </div>
  </div>
  
</template>

<script>

export default {
  name: 'HomePage',
  components: {
    
  },
  data() {
    return {
      message: 'Hello, this is a dynamic message from Home',
      isLoading: true,
      loadingPercentage: 0,
      items: [
        { title: 'Responsive Layout', description: 'Jugando un poco en Figma con un autolayout que se adapta en diferentes dispostivos.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15496154/media/9038def78fc75602977974f656a797df.gif' },
        { title: 'Composición Hero', description: 'Construyendo en Figma, usando 50% del layout un Bento Grid y texto en el otro 50%.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15459826/media/c3af271b58e97cbba375e4134f0ec2ad.png' },
        { title: 'Presentación Producto', description: 'Una transición para cargar estilos de cascos y explorar sus características. ', image: 'https://cdn.dribbble.com/users/916693/screenshots/13263333/media/cbbc013b209b92b1774da8915ffbed1f.gif' },
        { title: 'Idea SideProject', description: 'Soy fan del Downhill MTB, muchos riders quisieran ver las diferencias las bicis ganadoras de la copa mundo, un histórico por año y sus características.', image: 'https://cdn.dribbble.com/users/916693/screenshots/10975535/media/7bac0f195de27f66df377276ffd04d9a.jpg' },
        { title: 'Hero diseño interiores', description: 'Un hero interactivo que permite descubrir con interacción las imágenes de diseños de interiores.', image: 'https://cdn.dribbble.com/users/916693/screenshots/11011972/media/74b8742568f13b554883fb387f7e848a.jpg' },
        { title: 'Parrallax Hero', description: 'Una transición en scroll usando el efecto Parrallax.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15096289/media/406a840f996e7cc75b185bea5caddb61.gif' },
      ],
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.loadingPercentage < 100) {
        this.loadingPercentage += 10;
      } else {
        this.isLoading = false;
        clearInterval(interval);

        // Emitir evento al componente padre
        this.$emit('loading-complete', false);
      }
    }, 300);
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #222;

  &__progress {
    height: 100%;
    background-color: #e5f04b;
    transition: all 0.3s ease-in-out;
  }
}

.home-page {
  margin: 96px 16px;
  text-align: center;
  

  &__head {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    align-items: flex-start;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 94vh;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 70%);
      backdrop-filter: blur(32px); /* Aplicar el blur */
      -webkit-backdrop-filter: blur(32px); /* Compatibilidad con Safari */
      z-index: 0; // Coloca el gradiente detrás del contenido pero sobre el video
    }

    .home-page__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 94vh;
      object-fit: cover;
      z-index: -1; // Coloca el video detrás del contenido
    }

    // Asegúrate de que el contenido esté sobre el gradiente
    h1, &__left, &__right {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 780px) {
      gap: 12px;
    }


    &__title {
      color: white;
      flex-basis: 100%;
      text-align: left;
      font-weight: 600;
      font-size: 240px;
      margin: 56px 0;
      line-height: 70%;

      @media (max-width: 780px) {
        font-size: 96px;
      }
    }

    &__left {
      flex-basis: 33%;
      font-size: 22px;
      text-align: left;

      @media (max-width: 780px) {
        flex-basis: 100%;
      }

      p {
        color: #cecece;
        font-size: 28px;
        margin: 0px;
      }
    }

    &__right {
      color: white;
      display: flex;
      flex-direction: column;
      min-width: 320px;
      flex: 1;
      text-align: left;

      &__button {
        
        border: none;
        border-radius: 5px;
        color: #202020;
        cursor: pointer;
        background-color: #E5F04B;
        padding: 10px 20px;
        text-decoration: none;
        text-align: center;
        padding: 16px 24px;
        width: 240px;


        &:hover {
          background-color: #d0da44;
        }
      }

      p  {
        font-size: 40px;
        margin-bottom: 16px;
        margin-top: 0;
        
        @media (max-width: 780px) {
          font-size: 32px;
        }
      }

      span {

        padding-bottom: 24px;
        border-bottom: 1px solid #767676;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 96px;
    margin-top: 164px;

    &__columns {
      display: flex;
      gap: 2%;
      flex-wrap: wrap;
      text-align: left;
      width: 100%;

      &__content {
        color: white;
        width: 33%;

        @media (max-width: 780px) {
          width: 100%;
        }
      }

      img {
        border-radius: 20px;
        width: 65%;

        @media (max-width: 780px) {
          border-radius: 8px;
          width: 100%;
        }
      }

      h3 {
        font-size: 32px;
        margin: 32px 0 0 0;
        color: #cecece;
      }
    }

    &__news-item {
      margin-top: 30px;
    }

  }

  &__footer {
    background-color: #2f2f2f;
    padding: 48px;
    margin-top: 40px;
    border-radius: 16px;
    color: white;
  
    h3 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 20px;
    }

    &__experiment {
      border-radius: 16px;
      border: 1px solid white;
      margin-top: 56px;
    }
  }

}
</style>