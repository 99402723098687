<template>
  <div id="app">
   <div v-if="!isLoading" class="nav">
    <h3>milometaute</h3>
    <div class="links">
      <router-link to="/">Home</router-link>
      <router-link to="/playground">PlayGround</router-link>
    </div>
   </div>
    <router-view @loading-complete="handleLoadingComplete"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoading: true, // Estado inicial
    };
  },
  methods: {
    handleLoadingComplete(loadingState) {
      this.isLoading = loadingState; // Actualiza el estado desde el hijo
    },
  },
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

body {
  font-family: 'Euclid Circular A', sans-serif;
  background-color: #202020;
  height: 100vh;
  padding: 0 16px;

  .nav {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 32px;
    position: relative;
    z-index: 1;

    .links {
      display: flex;
      gap: 16px;
    }

    a {
      color: white;
    }

    h3 {
      color: white;
    }
  }
}
</style>