<template>
  <div class="playground-page">
    <h1>Estoy rediseñando Playground. 😬</h1>
    <p>Aquí es donde subo mis exploraciones con interacciones y layouts que veo interesantes, disfruto mucho hacer esto en mi tiempo libre.</p>

    <div class="tabs-container">
      <ul>
        <li v-for="option in options" :key="option" @click="selectOption(option)">
          <button :class="{ active: selected === option }">{{ option }}</button>
        </li>
      </ul>
    </div>
    
    <div class="grid-container">
      <div v-for="(image, index) in images" :key="index" class="grid-container--column" @click="openModal(image, index)">
        <img class="grid-container--column--img" :src="image.src" :alt="image.title">
      </div>
    </div>

    <Modal
      v-if="isModalOpen"
      :isVisible="isModalOpen"
      :imageSrc="selectedImage"
      :title="selectedTitle"
      :description="selectedDescription"
      @close="closeModal"
    >
      <!-- Renderiza dinámicamente el componente -->
      <component v-if="selectedComponent === 'CardIkea'" :is="selectedComponent" />
    </Modal>
    <CardAvatar />
  </div>
</template>

<script>
import Modal from '../components/ModalGallery.vue';
import CardIkea from '../components/CardIkea.vue';
import CardAvatar from '../components/CardAvatar.vue';

export default {
  components: {
    Modal,
    CardIkea,
    CardAvatar
  },
  data() {
    return {
      isOpen: false,
      selected: null,
      isModalOpen: false,
      selectedImage: '',
      selectedComponent: null, // Nuevo estado para el componente seleccionado
      options: ['Componentes', 'Experimentos & Efectos', 'Layouts', 'Páginas'],
      images: [],
      imagesSets: {
        'Componentes': [
          {
            src: 'https://images.unsplash.com/photo-1521017432531-fbd92d768814?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            title: 'Card IKEA',
            description: 'Este diseño me pareció interesante por su composición, amplio espacio y uso de colores.',
            component: 'CardIkea' // Pasa el nombre como string
          },
          {
            src: 'https://images.unsplash.com/photo-1506744038136-46273834b3fb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGFuZHNjYXBlfGVufDB8fDB8fHww',
            title: 'Montañas al amanecer 1',
            description: 'Una vista impresionante de las montañas con un cielo despejado al amanecer.'
          },
          {
            src: 'https://images.unsplash.com/photo-1494625927555-6ec4433b1571?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGFuZHNjYXBlfGVufDB8fDB8fHww',
            title: 'Montañas al amanecer 2',
            description: 'Una vista impresionante de las montañas con un cielo despejado al amanecer 2.'
          },
          {
            src: 'https://images.unsplash.com/photo-1472214103451-9374bd1c798e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGxhbmRzY2FwZXxlbnwwfHwwfHx8MA%3D%3D',
            title: 'Montañas al amanecer 3',
            description: 'Una vista impresionante de las montañas con un cielo despejado al amanecer 3.'
          }
        ],
        'Experimentos & Efectos': [
          {
            src: 'https://images.unsplash.com/photo-1483058712412-4245e9b90334?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Espacio de trabajo minimalista',
            description: 'Un espacio de trabajo limpio y ordenado con un ambiente creativo.'
          },
          {
            src: 'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Diseño de oficina moderna',
            description: 'Una oficina moderna con elementos de diseño únicos.'
          },
          {
            src: 'https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Oficina creativa',
            description: 'Un entorno de oficina con colores vibrantes y detalles creativos.'
          }
        ],
        'Layouts': [
            {
              src: 'https://plus.unsplash.com/premium_photo-1675435644687-562e8042b9db?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29mZmVlfGVufDB8fDB8fHww',
              title: 'Café en la mañana',
              description: 'Un taza de café en una mesa moderna, con luz natural matutina.'
            },
            {
              src: 'https://plus.unsplash.com/premium_photo-1669374537810-f88d8ad82818?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNvZmZlZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Taza de café y libro',
              description: 'Una mesa con una taza de café y un libro abierto, ideal para una pausa relajante.'
            },
            {
              src: 'https://images.unsplash.com/photo-1511537190424-bbbab87ac5eb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNvZmZlZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Diseño interior moderno',
              description: 'Un espacio interior moderno con muebles de diseño elegante.'
            }
        ],
        'Páginas': [
            {
              src: 'https://images.unsplash.com/photo-1485965120184-e220f721d03e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmlrZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Bicicleta en la ciudad',
              description: 'Una bicicleta estacionada en una calle tranquila de la ciudad.'
            },
            {
              src: 'https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YmlrZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Paseo en bicicleta',
              description: 'Un ciclista disfrutando de un paseo en una zona urbana.'
            },
            {
              src: 'https://images.unsplash.com/photo-1475666675596-cca2035b3d79?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGJpa2V8ZW58MHx8MHx8fDA%3D',
              title: 'Bicicleta vintage',
              description: 'Una bicicleta vintage apoyada en una pared de ladrillo, evocando nostalgia.'
            }
        ]
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selected = option;
      this.images = this.imagesSets[option];
      this.isOpen = false;
    },
    openModal(image) {
  this.selectedImage = image.src;
  this.selectedTitle = image.title;
  this.selectedDescription = image.description;
  this.selectedComponent = image.component; // Asegúrate de que sea 'CardIkea'
  console.log('selectedComponent:', this.selectedComponent); // Log para verificar el valor
  this.isModalOpen = true;
},
    closeModal() {
      this.isModalOpen = false;
      this.selectedImage = '';
      this.selectedComponent = null;
    }
  },
  created() {
    this.images = this.imagesSets['Componentes'];
  }
}
</script>
  
<style lang="scss" scoped>

  .playground-page {
    margin: 32px auto;
    max-width: 810px;
    text-align: left;

    .grid-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      gap: 16px;

      &--column {
        cursor: pointer;
        flex: 1;
        flex-basis: 340px;

        &--img {
          border-radius: 16px;
          cursor: pointer;
          object-fit: cover;
          height: 100%;
          width: 100%;

          &:hover {
            opacity: 20%;
          }
        }
      }
    }
  }

  h1 {
    color: #42b983;
  }

  p {
    color: white;
  }

  .tabs-container {
    ul {
      border: 1px solid #343434;
      border-radius: 200px;
      display: inline-flex;
      gap: 8px;
      list-style: none;
      padding: 8px;

      @media only screen and (max-width: 400px) {  
        overflow-x: scroll;
        width: 100%;
      }
      li { 
        button {
          background-color: #202020;
          border-radius: 200px;
          border: 1px solid #202020;
          color: white;
          cursor: pointer;
          padding: 10px 20px;
          transition: all 0.3s ease-in-out;
          white-space: nowrap;

          &:hover {
            border: 1px solid #41B983;
            img {
              transform: rotate(180deg);
            }
          }

          &.active {
            background-color: #2a2a2a; /* Cambia el color del botón activo */
            border: 1px solid #41B983;
          }
        }

      }
    }
  }
</style>