<template>
    <section class="row-card-avatar">
      <h1>Card Avatar</h1>
    </section>
  </template>
  
  <script>
  export default {
    name: 'CardAvatar'
  };
  </script>
  
<style lang="scss" scoped>
    .row-card-avatar {
        background-color: pink;

    }

</style>